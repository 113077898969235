/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useState } from "react";
import { CheckCircleIcon, RightArrowIcon } from "staysure-component-library";

import SupportDrawer from "@/components/atoms/drawer/support-drawer";
import Expander from "@/components/atoms/expander/expander-component";
import withMedicalFlow from "@/components/with-medical-flow-v2";
import { PRODUCT } from "@/constants";
import useMediaQuery from "@/hooks/useMediaQuery/use-media-query";
import { restructureObject } from "@/utils/magnolia/magnolia.utils";
import { renderSupportSection } from "@/utils/render-support-section.utils";

import Styles from "./question-detail-section.module.css";

type QuestionDetailSectionProps = {
  rightContent?: object | null;
  isdesktop?: boolean;
  supportDrawer?: object | null;
  loadMoreButtonText?: string;
  loadMoreButtonEnable?: boolean;
  styleClass?: string;
  supportImage?: string;
  medicalConfirmationPage?: boolean;
  showCompleted?: boolean;
  newMedicalFlowEnabled?: boolean;
  isUndiagnosedQuestion?: boolean;
};

function QuestionDetailSection({
  rightContent,
  supportDrawer,
  loadMoreButtonText,
  loadMoreButtonEnable,
  styleClass,
  supportImage,
  medicalConfirmationPage,
  showCompleted = false,
  newMedicalFlowEnabled,
  isUndiagnosedQuestion,
}: Readonly<QuestionDetailSectionProps>) {
  const restructuredObj = restructureObject(rightContent);
  const [open, setOpen] = useState(false);
  const onClickLoadMore = () => {
    setOpen(!open);
  };
  const [arrowIconColor, setArrowIconColor] = useState<string>("white");
  const isLargeDesktop = useMediaQuery("(min-width: 900px)");
  const isTablet = useMediaQuery("(min-width: 600px)");

  const brand: "staysure" | "avanti" =
    (PRODUCT as "staysure" | "avanti") ?? "staysure";

  useEffect(() => {
    if (medicalConfirmationPage) {
      const colorMap = {
        avanti: "#005A5E",
        staysure: "#306582",
      };

      setArrowIconColor(colorMap[brand] || "white");
    } else {
      setArrowIconColor("white");
    }
  }, [brand, medicalConfirmationPage]);

  const getBgStyles = () => {
    if (!medicalConfirmationPage) {
      return {};
    }

    let height;
    if (isLargeDesktop) {
      height = "100%";
    } else if (isTablet) {
      height = "650px";
    } else {
      height = "500px";
    }

    const justifyContent = isLargeDesktop ? "center" : "flex-start";

    return {
      backgroundImage: `url(${supportImage})`,
      backgroundRepeat: "no-repeat",
      width: "100%",
      height,
      justifyContent,
      backgroundPosition: "center bottom",
      backgroundSize: "cover ",
    };
  };
  return (
    <div
      data-testid="question-details-test-id"
      className={` flex flex-col justify-center items-center h-full 
      lg:min-h-[632px] md:pt-spacing-xxl md:pb-spacing-xxl pt-spacing-l pb-spacing-m`}
      style={{ ...getBgStyles() }}
    >
      <div className="w-full">
        {newMedicalFlowEnabled && showCompleted && (
          <div className="mb-spacing-m flex justify-center">
            <CheckCircleIcon fill="transparent" size="XL" />
          </div>
        )}
        {renderSupportSection(
          restructuredObj,
          styleClass,
          medicalConfirmationPage,
          isUndiagnosedQuestion
        )}
      </div>

      {loadMoreButtonEnable && loadMoreButtonText ? (
        <div className="load-more-wrapper">
          <div className="flex flex-row items-center md:mt-spacing-xs sm:mt-spacing-xs load-more-inner-wrapper">
            <div
              role="link"
              aria-label="read more on this question link"
              onClick={onClickLoadMore}
              className={`flex flex-row items-center cursor-pointer ${Styles.focusReadMoreLabel}`}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onClickLoadMore();
                }
              }}
              id="loadmoreButton-automate-test-id"
              data-testid="loadmoreButton-automate-test-id"
            >
              <span
                className={`${medicalConfirmationPage ? Styles.readMoreLabelMDFlow : Styles.readMoreLabel} font-semibold text-xs    
              focus:outline lg:hover:text-dark-surface-accent-2-primary leading-none`}
              >
                {loadMoreButtonText || "Read more on this question"}
              </span>
              <RightArrowIcon stroke={arrowIconColor} />
            </div>
          </div>
        </div>
      ) : null}

      <SupportDrawer setOpen={setOpen} isOpen={open}>
        {supportDrawer &&
          Object.entries(supportDrawer as object).map(([key, expanderData]) => {
            if (
              expanderData["mgnl:template"] === "quote:components/atom/expander"
            ) {
              return <Expander key={key} {...expanderData} />;
            }
            return null;
          })}
      </SupportDrawer>
    </div>
  );
}

export default withMedicalFlow(QuestionDetailSection);
