import React from "react";

import withBrand from "@/components/with-brand";

interface ParagraphProps {
  text: string;
  type: string;
  fontBold: boolean;
  brand?: "staysure" | "avanti";
}

const classNames = {
  avanti: {
    paragraphColor: "text-[#2E2E2E] ",
  },
  staysure: {
    paragraphColor: "text-text-body ",
  },
};

function Paragraph({
  text,
  type,
  fontBold,
  brand = "staysure",
}: Readonly<ParagraphProps>) {
  let style = "";
  if (type === "footer") {
    style = `font-body-font font-normal text-[14px] ${classNames[brand]?.paragraphColor} leading-[21px] pb-[20px]`;
  }
  return (
    <p
      data-testid="paragaph-testid"
      className={`${style} ${fontBold && " font-bold"}`}
    >
      {text}
    </p>
  );
}

export default withBrand(Paragraph);
