import React, { useState } from "react";

import withBrand from "@/components/with-brand";
import selectedColorBrand from "@/config/tailwind-colors/brand-colors";

import Styles from "./expander-interactive.module.css";
import Paragraph from "../paragraph/paragraph";

interface AccordionHeader {
  text: string;
  "mgnl:template": string;
  fontBold: boolean;
}

interface ExpanderInteractiveProps {
  renderer: React.ReactNode;
  accordionHeader: AccordionHeader;
  brand?: "avanti" | "staysure";
}

// eslint-disable-next-line react/function-component-definition
function ExpanderInteractive({
  renderer,
  accordionHeader,
  brand,
}: Readonly<ExpanderInteractiveProps>) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed);
    event.preventDefault();
  };

  const expanderTitle = () => {
    if (
      accordionHeader &&
      accordionHeader["mgnl:template"] === "quote:components/atom/paragraph"
    ) {
      return (
        <div className="text-l text-text-title leading-[27.5px] font-header-font font-normal">
          <Paragraph text={accordionHeader.text} type="" fontBold={false} />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="rounded-md mb-spacing-m sm:mt-spacing-m md:mt-spacing-l">
      <div className="flex-row">
        <button
          type="button"
          className={`text-black w-full text-left transition duration-300 mb-spacing-xs flex items-center justify-between ${Styles.accordianContainer} ${brand}`}
          onClick={toggle}
          data-testid="accordion-toggle"
        >
          {expanderTitle()}

          <div className="w-[24px] h-[24px]">
            <svg
              data-accordion-icon
              className={`float-right w-4 h-5 rotate-180 shrink-0 ${
                isCollapsed ? "!rotate-180" : "!rotate-0"
              }`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 7"
            >
              <path
                stroke={selectedColorBrand["text-title"]}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M9 5 5 1 1 5"
              />
            </svg>
          </div>
        </button>
      </div>

      {!isCollapsed && (
        <div
          className="text-black bg-white font-body-font"
          data-testid="accordion-content"
        >
          {renderer}
        </div>
      )}
    </div>
  );
}

export default withBrand(ExpanderInteractive);
