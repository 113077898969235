/* eslint-disable react/require-default-props */
import Image from "next/image";
import React from "react";

export interface MagnoliaImage {
  "@id": string;
  "@link": string;
  "@name": string;
  "@path": string;
  "@type": string;
  "@uuid": string;
}

export interface ImageProps {
  styleClass?: string;
  image?: MagnoliaImage | null;
  altText?: string;
  width?: number;
  height?: number;
  checkboxRounded?: string;
  clickableLink?: string;
}

function ImageAtom({
  styleClass = "",
  image = { "@link": "" } as MagnoliaImage,
  altText = "image",
  width = 1500,
  height = 800,
  checkboxRounded = undefined,
  clickableLink = "",
}: ImageProps): React.JSX.Element {
  const src = `${process.env.NEXT_PUBLIC_MGNL_HOST}/dam/${image?.["@id"]}${image?.["@path"]}`;
  const imageComponent = (
    <Image
      className={`${styleClass}${checkboxRounded && "bg-transparent"}`}
      loader={() => src}
      src={src}
      width={width}
      objectFit="cover"
      height={height}
      alt={altText}
    />
  );

  return clickableLink ? (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={clickableLink} target="_blank">
      {imageComponent}
    </a>
  ) : (
    imageComponent
  );
}

export default ImageAtom;
