import AssistantDescriptionContent from "@/components/molecules/question-detail-section/assistant-description/assistant-description-content";
import QuestionDescription from "@/components/molecules/question-detail-section/question-description/question-description";

export const renderSupportSection = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  restructuredObj: any,
  styleClass?: string,
  isNewMedicalConfirmationPage?: boolean,
  isUndiagnosedQuestion?: boolean
) => {
  if (
    restructuredObj.template ===
      "quote:components/molecules/question-description" ||
    restructuredObj.template ===
      "quote:components/molecules/organiser-question-description"
  ) {
    return (
      <QuestionDescription
        data-testid="rightContent-testid"
        questionBody={restructuredObj}
        title={restructuredObj?.title}
        isNewMedicalConfirmationPage={isNewMedicalConfirmationPage}
        isUndiagnosedQuestion={isUndiagnosedQuestion}
      />
    );
  }
  if (
    restructuredObj.template ===
      "quote:components/molecules/assistant-description" ||
    restructuredObj.template ===
      "quote:components/molecules/organiser-assistant-description"
  ) {
    return (
      <AssistantDescriptionContent
        data-testid="rightContent-testid"
        assistanceContent={restructuredObj}
        styleClass={styleClass}
      />
    );
  }
  return null;
};
