import { useAppDispatch, useAppSelector } from "@/store/hook";
import { setMedicalData } from "@/store/slices/medical.slice";
import { setQuestionData } from "@/store/slices/question/question.slice";
import { RootState } from "@/store/store";

const useResetStateTravelerEdit = () => {
  const dispatch = useAppDispatch();
  const { attributes } = useAppSelector((state: RootState) => state.quote);
  const { quoteResponse } = useAppSelector(
    (state: RootState) => state.question
  );

  const resetQuestionData = () => {
    dispatch(
      setQuestionData({
        ...quoteResponse,
        attributes: {
          ...quoteResponse?.attributes,
          organiser: attributes?.organiser || null,
          tripDetails: attributes?.tripDetails || null,
          travellerDetails: attributes?.travellerDetails || null,
        },
      })
    );

    // This is for update medical data reducer
    if (attributes?.travellerDetails?.gateKeeperAnswers) {
      dispatch(
        setMedicalData({
          ...attributes?.travellerDetails?.gateKeeperAnswers,
        })
      );
    }
  };

  return {
    resetQuestionData,
  };
};

export default useResetStateTravelerEdit;
