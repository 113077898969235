import { MEDICAL_FLOW_V2_ENABLED } from "@/constants";

const withMedicalFlow = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => {
  return function WithBrandComponent(props: P) {
    const newMedicalFlowEnabled = MEDICAL_FLOW_V2_ENABLED;
    return (
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        newMedicalFlowEnabled={newMedicalFlowEnabled === "true" || false}
      />
    );
  };
};

export default withMedicalFlow;
