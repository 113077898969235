/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from "react";

import RichText from "../rich-text/rich-text";

interface ExpanderItem {
  "mgnl:template": string;
  richText: string;
}
export interface ExpanderRendererProps {
  expanderItems: ExpanderItem;
  metadata?: any;
}
function ExpanderRenderer(props: Readonly<ExpanderRendererProps>) {
  if (
    props.expanderItems &&
    props.expanderItems["mgnl:template"] ===
      "quote:components/atom/rich-paragraph"
  ) {
    return (
      <div className="text-black bg-white">
        <RichText richText={props.expanderItems.richText} />
      </div>
    );
  }
}

export default ExpanderRenderer;
