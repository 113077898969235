/* eslint-disable @typescript-eslint/no-explicit-any */
import { MagnoliaCategory, MagnoliaNode } from "@/types/magnolia-utils";

export function normaliseMagnoliaResponse(
  node?: MagnoliaNode,
  childNodes: string[] = [],
  result: Record<string, unknown> = {}
): any {
  if (!node) return null;
  const nodes = childNodes.length ? childNodes : node?.["@nodes"];
  nodes.forEach((n) => {
    const magnoliaNode = node[n] as MagnoliaNode;
    const updatedNodes = magnoliaNode["@nodes"];

    // Omit unused magnolia props
    const {
      "@id": id,
      "@name": name,
      "@nodeType": nodeType,
      "@path": path,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      "@nodes": nodes,
      "mgnl:createdBy": createdBy,
      "mgnl:lastModified": lastModified,
      "mgnl:created": created,
      ...others
    } = magnoliaNode;
    Object.entries(others).forEach(([key, value]) => {
      if (value && typeof value === "object") {
        const valueNode = value as MagnoliaNode;

        // Map magnolia categories to their displayName property
        if (valueNode["@nodeType"] === "mgnl:category") {
          others[key] = (value as MagnoliaCategory).displayName;
          return;
        }
      }

      others[key] = value;
    });

    // eslint-disable-next-line no-param-reassign
    result[n] = others;

    // Run on nested nodes
    normaliseMagnoliaResponse(
      magnoliaNode,
      updatedNodes,
      result[n] as Record<string, unknown>
    );
  });

  return result;
}

function processNestedObject(nestedObj: any) {
  const resultArray: any = [];
  const nestedKeys = Object.keys(nestedObj);

  nestedKeys.forEach((key) => {
    const item = nestedObj[key];
    const newItem: any = {};

    Object.keys(item).forEach((itemKey) => {
      if (itemKey === "mgnl:template") {
        newItem.template = item[itemKey];
      } else {
        newItem[itemKey] = item[itemKey];
      }
    });

    if (typeof item === "object" && !Array.isArray(item) && item !== null) {
      resultArray.push(newItem);
    }
  });

  return resultArray;
}

export function restructureObject(obj: any) {
  const newObj: any = {};
  const keys = obj ? Object.keys(obj) : [];

  if (keys.length > 0) {
    const parentKey = keys[0];
    const parentObj = obj[parentKey];

    newObj.template = parentObj["mgnl:template"];
    newObj.title = parentObj?.title;
    newObj.child = [];

    Object.keys(parentObj).forEach((key) => {
      if (key !== "mgnl:template" && key !== "title") {
        const newChild: any = {};
        newChild[key] = processNestedObject(parentObj[key]);
        newObj.child.push(newChild);
      }
    });
  }

  return newObj;
}
