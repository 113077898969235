import selectStyleHeader from "@/utils/header.utils";

interface HeadlineProps {
  text: string;
  type: string;
  headerUsage: string;
}

export default function Header({ text, type, headerUsage }: HeadlineProps) {
  const style = selectStyleHeader(headerUsage);

  const renderHeader = (headerType: string = "h1") => {
    switch (headerType) {
      case "h1":
        return <h1 className={style}>{text}</h1>;
      case "h2":
        return <h2 className={style}>{text}</h2>;
      case "h3":
        return <h3 className={style}>{text}</h3>;
      case "h4":
        return <h4 className={style}>{text}</h4>;
      default:
        return <div />;
    }
  };

  return renderHeader(type);
}
