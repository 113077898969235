import React, { useEffect, useState } from "react";
import { SideDrawer, XIcon } from "staysure-component-library";

import withBrand from "@/components/with-brand";

import Styles from "./support-drawer.module.css";

type SupportDrawerProps = {
  isOpen?: boolean;
  assistantDescription?: object | null;
  children?: string | React.ReactNode;
  setOpen: (value: boolean) => void;
  onCloseAction?: () => void;
  brand?: "staysure" | "avanti";
};

const drawerWidth = 504;

function SupportDrawer({
  isOpen,
  children,
  setOpen,
  onCloseAction,
  brand,
}: Readonly<SupportDrawerProps>) {
  const innerWidth = typeof window !== "undefined" ? window.innerWidth : -1;
  const [windowWidth, setWindowWidth] = useState(innerWidth);

  useEffect(() => {
    // Function to update window width
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    if (typeof window !== "undefined") {
      window.addEventListener("resize", updateWindowWidth);
    }

    // Cleanup the event listener when the component unmounts
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", updateWindowWidth);
      }
    };
  }, []);

  const fontConfig = [
    {
      brand: "staysure",
      fontFamilyClass: "proxima-nova",
    },
    {
      brand: "avanti",
      fontFamilyClass: "faktum-semibold",
    },
    {
      brand: "expat",
      fontFamilyClass: "proxima-nova",
    },
  ];
  function getFontClasses(lBrand: string | undefined): string {
    let fontClasses = "";
    fontConfig.forEach((config) => {
      if (config.brand === lBrand) {
        fontClasses = `${config.fontFamilyClass}`;
      }
    });
    return fontClasses;
  }

  return (
    <SideDrawer
      isOpen={isOpen}
      onCloseDrawer={() => {
        setOpen(false);
        if (onCloseAction) onCloseAction();
      }}
      wrapperStyles={Styles.style}
      width={windowWidth >= 600 ? drawerWidth : windowWidth}
      drawerContentClassName={Styles.drawerContent}
      drawerClassName={Styles.drawer}
      closeBtnWrapperStyles={Styles.closeBtnWrapper}
      drawerContentWrapperClassName={`${Styles.drawerWrapper} sm:p-spacing-xl md:p-spacing-xxl`}
      closeButton={
        <div className="flex flex-row items-center justify-end cursor-pointer">
          <div
            className={`${Styles.btnLabel} text-[18px] outline-offset-0 text-interactive-secondary-default font-${getFontClasses(brand)}  font-semibold hover:bg-border-accent-1-primary hover:text-surface-neutral-option-1`}
          >
            Close
          </div>
          <XIcon stroke="#306582" />
        </div>
      }
    >
      {children}
    </SideDrawer>
  );
}

export default withBrand(SupportDrawer);
