import { ReactElement, useEffect, useState } from "react";
import { CheckCircleIcon, ToggleButton } from "staysure-component-library";

import {
  Europe,
  QuestionFive,
  QuestionMedical,
  QuestionOne,
  QuestionSix,
  QuestionThree,
  QuestionTwo,
  Worldwide,
  PostalSettings,
  ReviewAutoRenewal,
} from "@/config/app-constant";
import brandClassNames from "@/utils/question-description-classes.utils";

import Styles from "./button-group.module.css";
import { SECONDARY_PRODUCT } from "@/constants";

type ButtonGroupProps = {
  setSelectedAnswer: (answerNo: number) => void;
  selectedAnswer?: number | string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  answerArray: any[];
  location?: string;
  type?: string;
  isDisable?: boolean;
  loader?: ReactElement;
};

function ButtonGroup({
  setSelectedAnswer,
  selectedAnswer,
  answerArray,
  location,
  type = "auto",
  isDisable,
}: Readonly<ButtonGroupProps>) {
  const { toggleBtnText } = brandClassNames;
  const [selectedId, setSelectedId] = useState<
    number | string | null | undefined
  >(selectedAnswer);

  useEffect(() => {
    if (location === QuestionTwo || location === QuestionSix) {
      if (selectedId !== selectedAnswer) {
        setSelectedId(selectedAnswer);
      }
    } else {
      setSelectedId(selectedAnswer);
    }
  }, [selectedAnswer, selectedId, location]);

  const [isKeyBoardFocused, setIsKeyBoardFocused] = useState(true);

  const handleOnPressStart = (e: { pointerType: string }) => {
    if (e.pointerType === "keyboard") {
      setIsKeyBoardFocused(true);
    } else {
      setIsKeyBoardFocused(false);
    }
  };

  // eslint-disable-next-line consistent-return
  function getMainStyle(pageLocation: string | undefined) {
    if (pageLocation === QuestionOne) {
      return "lg:flex lg:flex-col md:grid md:grid-cols-2 lg:gap-0 md:gap-spacing-s flex-col md:mt-spacing-l mt-spacing-m";
    }

    if (pageLocation === QuestionTwo) {
      return `${Styles.amt_togglebtn_wrapper}
      grid md:grid-cols-2 sm:grid-cols-1 md:gap-4 ${SECONDARY_PRODUCT ? "lg:grid-cols-2" : "lg:grid-cols-3"} md:mt-spacing-l mt-spacing-m`;
    }
    if (pageLocation === Worldwide || pageLocation === Europe) {
      return `${Styles.amt_togglebtn_wrapper}
      grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 md:gap-4 md:mt-spacing-l mt-spacing-m`;
    }
    if (pageLocation === QuestionThree || pageLocation === QuestionMedical) {
      return " flex flex-row gap-x-2 md:mt-spacing-l mt-spacing-m btn-group-wrapper";
    }
    if (pageLocation === QuestionFive) {
      return " flex flex-col mt-spacing-l text-text-on-primary-default";
    }
    if (pageLocation === PostalSettings) {
      return `${Styles.amt_togglebtn_wrapper}
      flex md:flex-row lg:w-[352px] flex-col gap-x-2 md:mt-spacing-l mt-spacing-m btn-group-wrapper  `;
    }
    if (pageLocation === ReviewAutoRenewal) {
      return `${Styles.review_auto_renewal_togglebtn_wrapper} flex flex-col gap-[16px] mt-spacing-m`;
    }
  }

  return (
    <div
      data-testid="button-group-test-id"
      className={getMainStyle(location)}
      //   className=" flex flex-col md:mt-spacing-l mt-spacing-m"
    >
      {answerArray?.map(
        (answer: {
          id: number;
          label: string;
          value: boolean;
          description: string;
        }) => {
          const { id, label, description } = answer;
          const isSelected = selectedId === id;
          return (
            // eslint-disable-next-line react/jsx-key
            <ToggleButton
              btnType="button"
              data-testid={`${type}-buttons-group-${id}`}
              id={`${type}-buttons-group-${id}`}
              isToggleEnable={false}
              type="button"
              key={`toggleButton-${id}`}
              onClick={() => {
                setSelectedAnswer(id);
                setSelectedId(id);
              }}
              onFocusChange={(e) => setIsKeyBoardFocused(e)}
              onPressStart={(e: { pointerType: string }) =>
                handleOnPressStart(e)
              }
              description={description}
              isActiveRightIcon
              activeRightIcon={<CheckCircleIcon />}
              isDefaultActive={isSelected}
              label={label}
              className={`${Styles.toggle_btn} ${Styles.focus_class} ${isKeyBoardFocused ? "keyBoardFocused" : ""}
                            active:bg-interactive-secondary-default active:text-dark-text-on-primary border-[1px]
                            bg-surface-neutral-option-1
                            border-interactive-secondary-default
                            text-${location === QuestionFive ? "text-on-primary-default" : "text-on-primary"} w-full h-[56px] rounded-lg text-2xs
                             ${location !== QuestionFive ? " font-semibold " : `${Styles.policy_togglebtn_wrapper} text-left mb-spacing-xs`}
                             ${location === ReviewAutoRenewal ? `${Styles.policy_togglebtn_wrapper}  text-left mb-spacing-xs h-auto  hover:px-[13px] ` : ""}
                             ${location === QuestionMedical ? `${Styles.medical_gatekeeper_toggle_btn} lg:mb-spacing-s md:mb-5` : ""}
                            font-body-font leading-[24px] 
                            py-spacing-s px-spacing-s hover:px-[11px] flex flex-col justify-center self-start
                            gap-grid-system-space-050
                            hover:bg-interactive-action-hovered mb-spacing-s ${toggleBtnText}}`}
              isDisabled={isDisable}
            />
          );
        }
      )}
    </div>
  );
}

export default ButtonGroup;
