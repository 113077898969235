import { usePathname } from "next/navigation";
import { useContext, useEffect, useState, createContext } from "react";

const useNavigation = () => {
  const pathname = usePathname();

  const [currentRoute, setCurrentRoute] = useState<string | null>(null);
  const [previousRoute, setPreviousRoute] = useState<string | null>(null);

  useEffect(() => {
    const url = `${pathname}`;
    const cleanedUrl = url.replace(/\/+$/, "");
    setPreviousRoute(cleanedUrl);
    setCurrentRoute(url);
  }, [pathname, currentRoute]);

  return { previousRoute };
};

const NavigationContext = createContext<ReturnType<typeof useNavigation>>({
  previousRoute: null,
});

export const useNavigationContext = () => useContext(NavigationContext);

export function NavigationProvider({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const navigation = useNavigation();

  return (
    <NavigationContext.Provider value={navigation}>
      {children}
    </NavigationContext.Provider>
  );
}
