import { PRODUCT } from "@/constants";
import { renderMagnoliaEditor } from "@/utils/magnolia/magnolia-component-editor.utils";
import brandClassNames from "@/utils/question-description-classes.utils";

import Styles from "./questions.module.css";

type QuestionDescriptionProps = {
  questionBody?: object | null;
  title?: string;
  detailsLinkText?: string;
  isNewMedicalConfirmationPage?: boolean;
  isUndiagnosedQuestion?: boolean;
};
function QuestionDescription({
  title,
  questionBody = null,
  isNewMedicalConfirmationPage = false,
  isUndiagnosedQuestion = false,
}: Readonly<QuestionDescriptionProps>) {
  const { paragraphColor } = brandClassNames;
  const brand: "staysure" | "avanti" =
    (PRODUCT as "staysure" | "avanti") ?? "staysure";

  function getSubTitleStyle() {
    if (brand === "staysure" && isNewMedicalConfirmationPage) {
      return Styles.newMedicalStaysureSubTitle;
    }
    if (brand === "avanti" && isNewMedicalConfirmationPage) {
      return Styles.newMedicalAvantiSubTitle;
    }
    return "";
  }
  const fontConfig = [
    {
      brand: "staysure",
      fontLeadingClass: `${isNewMedicalConfirmationPage ? "leading-[27.6px] md:leading-[34.1px]" : "leading-[21px] md:leading-[34.1px]"}`,
      fontSizeClass: `${isNewMedicalConfirmationPage ? "text-[23px]" : "text-[20px]"}`,
      fontColorClass: `${(() => {
        if (isNewMedicalConfirmationPage) {
          return isUndiagnosedQuestion
            ? "text-surface-neutral-option-1"
            : "text-text-title";
        }
        return "text-surface-neutral-option-1";
      })()}`,
      fontMarginBottomClass: `${isNewMedicalConfirmationPage ? "mb-spacing-m" : "md:mb-spacing-xs mb-spacing-m"}`,
      fontWeightClass: "font-normal",
    },
    {
      brand: "avanti",
      fontLeadingClass: `${isNewMedicalConfirmationPage ? "leading-[26px] md:leading-[34.1px]" : "leading-[21px] md:leading-[34.1px]"}`,
      fontSizeClass: `${isNewMedicalConfirmationPage ? "text-[23px]" : "text-[20px]"}`,
      fontColorClass: `${(() => {
        if (isNewMedicalConfirmationPage) {
          return isUndiagnosedQuestion
            ? "text-surface-neutral-option-1"
            : "text-text-title";
        }
        return "text-surface-neutral-option-1";
      })()}`,
      fontMarginBottomClass: `${isNewMedicalConfirmationPage ? "mb-spacing-m" : "md:mb-spacing-xs mb-spacing-m"}`,
      fontWeightClass: "font-bold",
    },
  ];

  function getTitleFontClasses(lBrand: string | undefined): string {
    let fontClasses = "";
    fontConfig.forEach((config) => {
      if (config.brand === lBrand) {
        fontClasses = `${config.fontLeadingClass}  ${config.fontSizeClass} ${config.fontMarginBottomClass} ${config.fontColorClass} ${config.fontWeightClass}`;
      }
    });
    return fontClasses;
  }

  const descriptionFontConfig = [
    {
      brand: "staysure",
      fontLeadingClass: "leading-[24px] md:leading-[27px]",
    },
    {
      brand: "avanti",
      fontLeadingClass: `${isNewMedicalConfirmationPage ? "leading-[27px]" : "leading-[24px] md:leading-[27px]"}`,
    },
  ];

  function getDescriptionFontClasses(lBrand: string | undefined): string {
    let fontClasses = "";
    descriptionFontConfig.forEach((config) => {
      if (config.brand === lBrand) {
        fontClasses = `${config.fontLeadingClass}`;
      }
    });
    return fontClasses;
  }

  return (
    <div
      data-testid="question-description-test-id"
      className={` lg:px-spacing-m  ${isNewMedicalConfirmationPage ? "px-[24px]  xl:px-[56px] tablet:px-[56px]" : "px-spacing-m  xl:px-spacing-5xl tablet:px-spacing-6xl"} w-full answer-block`}
    >
      <div className=" lg:max-w-[325px] md:max-w-[432px] mx-auto">
        <h2
          className={`md:!text-3xl  font-header-font
  ${!isNewMedicalConfirmationPage && "md:text-left"} text-center   ${getTitleFontClasses(brand)}`}
        >
          {title}
        </h2>
        <div
          className={`w-full md:p-0 rounded-radius-lg md:border-none 
      ${!isNewMedicalConfirmationPage && "border-border-transparent border bg-surface-accent-transparent p-spacing-m"}  md:bg-transparent `}
        >
          <div
            className={`${Styles.question_Paragraph_wrapper} md:text-xs ${getDescriptionFontClasses(brand)}
            text-2xs  ${isNewMedicalConfirmationPage ? "text-center" : "text-left"} md:mb-spacing-m ${paragraphColor} ${
              isUndiagnosedQuestion
                ? "text-surface-neutral-option-2"
                : getSubTitleStyle()
            }`}
          >
            {questionBody && (
              <div>{renderMagnoliaEditor(questionBody, "questionBody")}</div>
            )}
          </div>
          <div
            className={`${Styles.question_Paragraph_wrapper} md:text-xs text-left md:leading-[27px] 
            text-2xs leading-[24px] md:mb-spacing-m`}
          >
            {/* {detailsLinkText && <EditableArea content={detailsLinkText} />} */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default QuestionDescription;
