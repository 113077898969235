import { useSpring, animated, SpringRef, Lookup } from "@react-spring/web";
import React, { useEffect, useState } from "react";

import { useNavigationContext } from "@/providers/navigation-provider";
import { PageName, getRouterPathMainButton } from "@/utils/page-routing.utils";

function TransformAnimation({
  children,
  animationRef,
  currentPage,
  className,
  isSubSectionFilled,
}: Readonly<{
  animationRef?: SpringRef<Lookup>;
  children: React.ReactNode;
  currentPage: string;
  className?: string;
  isSubSectionFilled?: boolean;
}>) {
  const [display, setDisplay] = useState("block");
  const [reverseAnimation, setReverseAnimation] = useState(false);
  const [pauseAnimation, setPauseAnimation] = useState(true);

  const { previousRoute } = useNavigationContext();

  useEffect(() => {
    const currentPageValue =
      currentPage === "cover-type" ? "travelling-from" : currentPage;
    if (
      currentPage === "organiser" &&
      (previousRoute === "/travellers" || previousRoute === "/traveller-health")
    ) {
      setReverseAnimation(true);
    } else if (
      getRouterPathMainButton(currentPageValue as PageName) === previousRoute
    ) {
      setReverseAnimation(true);
    }
    setPauseAnimation(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousRoute]);

  let fromAnimation = "100px";
  if (isSubSectionFilled) {
    fromAnimation = "0px";
  } else if (reverseAnimation) {
    fromAnimation = "-100px";
  }

  const slideInAnimation = useSpring({
    ref: animationRef,
    from: { opacity: 0, transform: `translateX(${fromAnimation})` },
    to: [
      {
        opacity: 1,
        transform: `translateX(0px)`,
      },
    ],
    onRest: () => {
      setDisplay("contents");
    },
    config: { tension: 120, friction: 16, mass: 1 },
    pause: pauseAnimation,
  });
  return (
    <animated.div
      style={{ ...slideInAnimation, display }}
      className={className}
    >
      {children}
    </animated.div>
  );
}

export default TransformAnimation;
