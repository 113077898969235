import { PRODUCT } from "@/constants";

function stsSelectStyleHeader(headerImplementation: string): string {
  let style: string = "";

  if (headerImplementation === "question_header") {
    style =
      " font-header-font lg:text-[39px] md:text-3xl text-[26px] lg:leading-[44.85px] md:leading-[34.1px] leading-[29.9px] text-text-title ";
  } else if (headerImplementation === "sevirity_title") {
    style =
      "md:text-3xl text-[20px] md:mb-spacing-m mb-spacing-xs font-header-font md:leading-[34.1px] leading-[21px] text-center text-dark-text-on-primary";
  } else if (headerImplementation === "sevirity_sub_title") {
    style =
      " md:text-xs text-2xs text-center md:leading-[27px] leading-[24px] mb-spacing-m ";
  }

  return style;
}

function avnSelectStyleHeader(headerImplementation: string): string {
  let style: string = "";

  if (headerImplementation === "question_header") {
    style =
      " font-header-font lg:text-[39px] md:text-3xl text-[26px] lg:leading-[44.85px] md:leading-[34.1px] leading-[29.9px] text-text-title ";
  } else if (headerImplementation === "sevirity_title") {
    style =
      "md:text-3xl text-[20px] md:mb-spacing-m mb-spacing-xs font-header-font md:leading-[34.1px] leading-[21px] text-center text-dark-text-on-primary";
  } else if (headerImplementation === "sevirity_sub_title") {
    style =
      " md:text-xs text-2xs text-center md:leading-[27px] leading-[24px] mb-spacing-m text-dark-text-on-primary";
  }

  return style;
}

const defaultFunction = {
  avanti: avnSelectStyleHeader,
  staysure: stsSelectStyleHeader,
};

const brand: "staysure" | "avanti" =
  (PRODUCT as "staysure" | "avanti") ?? "staysure";

const selectedHeader = defaultFunction[brand];

export default selectedHeader;
