import { PRODUCT } from "@/constants";

const classNames = {
  avanti: {
    paragraphColor: " text-surface-neutral-option-1 ",
    toggleBtnText: " text-text-title ",
  },
  staysure: {
    paragraphColor: " text-surface-neutral-option-2 ",
    toggleBtnText: " text-functional ",
  },
};

const brand: "staysure" | "avanti" =
  (PRODUCT as "staysure" | "avanti") ?? "staysure";

const brandClassNames = classNames[brand];

export default brandClassNames;
