import { renderMagnoliaEditor } from "@/utils/magnolia/magnolia-component-editor.utils";
import brandClassNames from "@/utils/question-description-classes.utils";

type AssistantDescriptionProps = {
  assistanceContent?: {
    template: string;
    child: object[];
  } | null;
  styleClass?: string;
};

function AssistantDescriptionContent({
  assistanceContent = null,
  styleClass,
}: Readonly<AssistantDescriptionProps>) {
  const { paragraphColor } = brandClassNames;
  return (
    <div
      data-testid="assistant-description-test-id"
      className=" flex flex-col justify-center items-center md:px-spacing-xxxl px-spacing-l"
    >
      <div
        className={`text-center lg:max-w-none md:max-w-[560px] w-full mx-auto ${paragraphColor}`}
      >
        {renderMagnoliaEditor(
          assistanceContent,
          "userHeaderSection",
          styleClass
        )}
      </div>
    </div>
  );
}
export default AssistantDescriptionContent;
