import { useSpring, animated, SpringRef, Lookup } from "@react-spring/web";
import React from "react";

function FadeAnimation({
  animationRef,
  children,
  className,
}: Readonly<{
  animationRef?: SpringRef<Lookup>;
  children: React.ReactNode;
  className?: string;
}>) {
  const fadeAnimation = useSpring({
    ref: animationRef,
    from: { opacity: 0 }, // Starts with no opacity (hidden)
    to: { opacity: 1 }, // Fades in when visible
    // config: { duration: 500 }, // Adjust the duration as needed
  });
  return (
    <animated.div style={fadeAnimation} className={className}>
      {children}
    </animated.div>
  );
}

export default FadeAnimation;
