import ImageAtom from "@/components/atoms/image/image";
import Paragraph from "@/components/atoms/paragraph/paragraph";

type TrustPilotProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trustpilotText?: any | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trustpilotImage?: any | null;
};

function TrustPilot({
  trustpilotText,
  trustpilotImage,
}: Readonly<TrustPilotProps>) {
  return (
    <div
      className="flex flex-row py-spacing-m lg:px-spacing-xl px-spacing-m justify-between 
      bg-transparent-overlay lg:rounded-t-radius-xxl lg:rounded-b-none rounded-b-radius-xxl"
    >
      <div className=" w-full max-w-none md:max-w-[432px] flex justify-between mx-auto">
        <div
          className=" font-body-font text-[14px] leading-[21px] lg:mr-spacing-m md:mr-spacing-l 
        mr-spacing-m lg:max-w-none max-w-[261px] w-full"
        >
          {trustpilotText && (
            <Paragraph
              text={trustpilotText.text}
              type=""
              fontBold={trustpilotText.fontBold}
            />
          )}
        </div>
        {trustpilotImage && (
          <div className="max-w-[140px] w-full">
            <ImageAtom
              image={trustpilotImage.image}
              clickableLink={trustpilotImage.clickableLink}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default TrustPilot;
