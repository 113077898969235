import Header from "@/components/atoms/header/header";
import Paragraph from "@/components/atoms/paragraph/paragraph";
import RichText from "@/components/atoms/rich-text/rich-text";

// Define a union type for component mappings
type ComponentMapping = {
  [key in
    | "quote:components/molecules/assistant-description"
    | "quote:components/molecules/organiser-assistant-description"
    | "quote:components/atom/header"
    | "quote:components/atom/paragraph"
    | "quote:components/atom/rich-paragraph"]: React.ComponentType<any>;
};

export function renderMagnoliaEditor(
  restructuredObj: any,
  objectKey: string,
  styleClass?: string
) {
  return (
    <>
      {restructuredObj?.child.map((obj: any) => {
        const componentProps = obj[objectKey];
        return componentProps?.map((childComponent: any, index: number) => {
          const template: keyof ComponentMapping = childComponent.template;
          if (template === "quote:components/atom/header") {
            return <Header key={template + index} {...childComponent} />;
          }
          if (template === "quote:components/atom/paragraph") {
            return <Paragraph key={template + index} {...childComponent} />;
          }
          if (template === "quote:components/atom/rich-paragraph") {
            return (
              <RichText
                key={template + index}
                {...childComponent}
                className={styleClass}
              />
            );
          }
        });
      })}
    </>
  );
}
