import React from "react";

import ExpanderInteractive from "./expander-interactive";
import ExpanderRenderer from "./expander-renderer";

interface AccordionHeader {
  text: string;
  "mgnl:template": string;
  fontBold: boolean;
}

interface ExpanderItem {
  "mgnl:template": string;
  richText: string;
}

export interface AccordionData {
  accordionHeader: {
    [key: string]: AccordionHeader;
  };
  expanderItems: {
    [key: string]: ExpanderItem;
  };
}

function Expander({
  accordionHeader,
  expanderItems,
}: AccordionData): React.JSX.Element {
  const renderer = <ExpanderRenderer expanderItems={expanderItems["0"]} />;
  return (
    <ExpanderInteractive
      renderer={renderer}
      accordionHeader={accordionHeader["0"]}
    />
  );
}

export default Expander;
