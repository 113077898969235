import { usePathname, useRouter } from "next/navigation";
import { useCallback, useEffect } from "react";

const useReplaceRouterPath = (routerPath: string) => {
  const router = useRouter();
  const pathname = usePathname();

  const replaceRouterPath = useCallback(() => {
    const isRouteFromMedicalDashBoard = sessionStorage.getItem(
      "isRouteFromMedicalDashBoard"
    );

    if (isRouteFromMedicalDashBoard === "true") {
      router.replace(pathname);
    }
  }, [pathname, router]);

  useEffect(() => {
    replaceRouterPath();
  }, [routerPath, pathname, router, replaceRouterPath]);
};

export default useReplaceRouterPath;
