import { useMemo } from "react";
import { LeftArrowIcon } from "staysure-component-library";

import BtnLoadingSvg from "@/components/atoms/btn-loading-svg/btn-loading-svg";
import LabelButtonWrapper, {
  IconButtonWrapper,
} from "@/components/atoms/button-wrapper/button-wrapper";
import withBrand from "@/components/with-brand";
import {
  LandingPage,
  MainView,
  QuestionDisable,
  QuestionOne,
  QuestionTwo,
  QuestionThree,
  QuestionFour,
  QuestionSix,
  QuestionMedicalConfirmation,
  QuestionMedicalConditions,
  QuestionMedicalTreatments,
  QuestionFive,
  QuestionSeven,
  QuestionEight,
} from "@/config/app-constant";
import { ActionButtonProps, ButtonStyle } from "@/types/ActionButtonType";

import Styles from "./action-button.module.css";
import { ExpatButton } from "../cta-button/cta-buttons";

type PageType =
  | typeof LandingPage
  | typeof QuestionOne
  | typeof QuestionTwo
  | typeof QuestionThree
  | typeof QuestionFour
  | typeof QuestionSix
  | typeof QuestionMedicalConfirmation
  | typeof QuestionMedicalConditions
  | typeof QuestionMedicalTreatments
  | typeof QuestionFive
  | typeof QuestionSeven;

function ActionButton({
  disableMain = false,
  ctaButtonText = "Agree and Continue",
  page,
  expatButton,
  isLoading = false,
  showBackBtn = true,
  submitButtonClick,
  backButtonClick,
  brand = "staysure",
}: Readonly<ActionButtonProps>) {
  const styleMainDiv = useMemo(() => {
    const baseClasses = `flex fixed py-spacing-s left-0 bottom-0 bg-surface-neutral-option-1 w-full px-spacing-l 
    shadow-[0_4px_4px_0px_#00000040] md:fixed md:py-spacing-s lg:relative lg:shadow-none lg:bg-transparent z-[3] `;
    const pageClassesMap = {
      [LandingPage]: `pt-spacing-s col-span-12 text-center justify-center md:pt-spacing-xl md:pb-0 pb-spacing-s`,
      [QuestionOne]: `justify-center text-center md:mt-spacing-xxl md:px-0 md:py-0`,
      [QuestionTwo]: `justify-between z-[2] md:px-0 md:py-0 md:justify-center`,
      [QuestionThree]: `flex-row justify-between md:mt-spacing-xxl md:px-0 md:py-0 md:justify-center `,
      [QuestionMedicalConfirmation]: `flex-row justify-between md:mt-spacing-xl lg:mt-spacing-xxl md:px-0 md:py-0 md:justify-center`,
      [QuestionMedicalConditions]: `flex-row justify-between md:mt-spacing-xl lg:mt-spacing-xxl md:px-0 md:py-0 md:justify-center`,
      [QuestionMedicalTreatments]: `flex-row justify-between md:mt-spacing-xl lg:mt-spacing-xxl md:px-0 md:py-0 md:justify-center`,
      [QuestionSix]: `flex-row justify-between md:mt-spacing-xxl md:px-0 md:py-0 md:justify-center`,
      [QuestionFive]: `flex-row justify-between md:mt-spacing-xxl md:px-0 md:py-0 md:justify-center`,
      [QuestionFour]: `flex-row justify-between md:mt-spacing-xxl md:px-0 md:py-0 md:justify-center`,
      [QuestionSeven]: `flex-row justify-between md:mt-spacing-xxl md:px-0 md:py-0 md:justify-center`,
      [QuestionEight]: `flex-row justify-between md:mt-spacing-xxl md:px-0 md:py-0 md:justify-center`,
    };
    return baseClasses + (page ? pageClassesMap[page as PageType] : "");
  }, [page]);

  const buttonStyle: ButtonStyle = {
    [MainView]: `${page === "cover-type" && "lg:max-w-[342px]"} w-full rounded-radius-pill px-spacing-l
  py-spacing-s font-body-font text-text-on-primary font-semibold leading-[18px] h-spacing-xxxl
  bg-interactive-primary-default-1 border-interactive-primary-default-1 hover:bg-interactive-primary-hovered
  hover:border-interactive-primary-hovered active:bg-interactive-primary-pressed-2 active:border-interactive-primary-pressed-2
   text-xs relative z-[2] md:w-[244px] landscape:w-[604px]`,
    [QuestionDisable]: `bg-interactive-primary-default-1 border-interactive-primary-default-1
  hover:bg-interactive-primary-hovered hover:border-interactive-primary-hovered text-text-on-primary 
     border-[1.5px] active:bg-interactive-primary-pressed-2 
   active:border-interactive-primary-pressed-2 flex flex-col justify-center items-center 
    ${page === "cover-type" && "lg:max-w-[342px]"} w-full rounded-radius-pill 
  px-spacing-l py-spacing-s disabled:bg-surface-neutral-option-5 disabled:border-surface-neutral-option-5 
  disabled:text-text-disabled font-body-font
   text-xs leading-[18px] font-semibold h-[56px]  relative z-[2] md:w-[244px] landscape:w-[604px]`,
  };

  function getButtonStyle(usage: string, disable: boolean) {
    let key = MainView;
    if (usage !== LandingPage && disable) {
      key = QuestionDisable;
    }

    return buttonStyle[key as keyof ButtonStyle];
  }

  return (
    <div className={styleMainDiv} data-testid="action-button-id">
      <div className=" backdrop-blur-[1.5px] md:hidden absolute h-full w-full z-[1] top-[1px] bottom-[1px]" />
      {showBackBtn && (
        <IconButtonWrapper
          id={`${page}-page-back-button`}
          aria-label="Back"
          data-testid={`${page}-page-back-button`}
          btnType="icon"
          icon={<LeftArrowIcon brand={brand} />}
          hierarchy="secondary"
          className={`${Styles.icon_btn} ${Styles.back_button} md:mr-spacing-m mr-spacing-s z-[2] relative w-full max-w-[56px] h-[56px] rounded-radius-pill hover:!bg-interactive-action-hovered `}
          onClick={backButtonClick}
        />
      )}

      {!expatButton ? (
        <LabelButtonWrapper
          id={`${page}-page-submit-button`}
          data-testid={`${page}-page-submit-button`}
          isDisabled={disableMain}
          label={ctaButtonText}
          isLoading={isLoading}
          loadingIcon={isLoading ? <BtnLoadingSvg /> : <div />}
          hierarchy="primary"
          className={getButtonStyle(page ?? "", disableMain)}
          type="button"
          onClick={submitButtonClick}
        />
      ) : (
        <ExpatButton
          id={`${page}-page-expat-button`}
          data-testid={`${page}-page-expat-button`}
          label={ctaButtonText}
          hierarchy="primary"
          className={`${Styles.expat_btn} w-[264px] h-[48px] mb-spacing-s px-spacing-l py-spacing-s z-[2]`}
          onClick={submitButtonClick}
        />
      )}
    </div>
  );
}

export default withBrand(ActionButton);
